import React from "react";
import { useEffect } from 'react';
import './pickup-date.styles.scss';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { addDays } from "date-fns";

//day 0=sunday, 1=monday
// function getDayForYear(year, day) {
//   var date = new Date(`1/1/${year}`);
//   while (date.getDay() !== day) {
//     date.setDate(date.getDate() + 1);
//   }
//   var days = [];
//   while (date.getFullYear() === year) {
//     days.push(date);
//     date = addDays(date, 7);
//   }
//   return days;
// }

const getExcludedDates = () => {
  let excludeDates = [


    new Date("09/11/2024"),
    new Date("09/07/2024"),
    new Date("09/08/2024")
  ]

  //add to excluded dates 3/21/2025 through 3/30/2025
  let startDate = new Date("03/21/2025");
  let endDate = new Date("04/02/2025");
  while (startDate <= endDate) {
    excludeDates.push(new Date(startDate));
    startDate = addDays(startDate, 1);
  }

  // const currentYear = new Date().getFullYear();
  // excludeDates.push(...getDayForYear(currentYear, 1)); //thisYearMonday
  // excludeDates.push(...getDayForYear(currentYear + 1, 1)); //next year monday
  // excludeDates.push(...getDayForYear(currentYear, 4)); //this year thursday
  // excludeDates.push(...getDayForYear(currentYear + 1, 4)); //next year thursday
  return excludeDates;
};

const includedInExcludedDates = (date) => {
  return getExcludedDates().some(excludedDate => excludedDate.getTime() === date.getTime());
}



const PickupDate = ({ startDate, setStartDate, originalStartDate }) => {

  useEffect(() => {
    // const excludedDaysOfWeek = [1, 4]; // monday and thursday
    // const excludedDaysOfWeek = [1]; //monday
    const excludedDaysOfWeek = []; // no excluded days of week
    if (excludedDaysOfWeek.includes(startDate.getDay()) || includedInExcludedDates(startDate)) {
      console.log('increased day')
      setStartDate(addDays(startDate, 1));
    };
  }, [setStartDate, startDate]);


  return (
    <div className="pickup-date-wrapper">
      <span className="pickup-date-label">I'd like to pick it up:</span> <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        minDate={originalStartDate}
        maxDate={addDays(new Date(), 60)}
        excludeDates={getExcludedDates()}
      />
    </div>
  );
};

export default PickupDate;